import { RequirementsProps } from './context';

export type Disability = 'AUDITIF' | 'MENTAL' | 'MOTEUR' | 'VISUEL';

const getQueryString = (name: string, url?: string) => {
  if (typeof window === 'undefined') return null;
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const replaceUrlParam = (paramName, paramValue, href) => {
  let url = href;
  var pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');

  if (paramValue == null || paramValue === '') {
    return url.replace(pattern, '');
  }

  if (url.search(pattern) >= 0) {
    return url.replace(pattern, '$1' + paramValue + '$2');
  }
  url = url.replace(/[?#]$/, '');
  return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
};

const replaceQueryString = (query, value) => {
  window.history.replaceState({}, '', replaceUrlParam(query, value, window.location.href));
};

const isDebugMode = () => {
  return getQueryString('debug');
};

const getQueryStrings = (url?: string) => {
  if (typeof window === 'undefined') return null;
  if (!url) url = window.location.href.replace(window.location.hash, '');

  const urlSplit = url.split('?');
  if (urlSplit.length <= 1) return null;

  const output = {};
  urlSplit[1].split('&').forEach((s) => {
    const ss = s.split('=');
    output[ss[0]] = ss[1];
  });

  return output;
};

const getCookies: () => { [key: string]: any } = () => {
  if (typeof window === 'undefined' || !document.cookie) return {};

  return document.cookie
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent((v[0] || '').trim())] = decodeURIComponent((v[1] || '').trim());
      return acc;
    }, {});
};

const getOrCreateCiblerId = () => {
  const cookies = getCookies();

  if (cookies.cibler_id) return cookies.cibler_id;

  const id = `CIB1.${Math.floor(Math.random() * 1e9)}.${Math.floor(new Date().getTime() / 1000)}`;
  document.cookie = `cibler_id=${id}`;
  return id;
};

const isWebview = (ua) => {
  const rules = [
    // if it says it's a webview, let's go with that
    'WebView',
    // iOS webview will be the same as safari but missing "Safari"
    '(iPhone|iPod|iPad)(?!.*Safari)',
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
    'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
    // old chrome android webview agent
    'Linux; U; Android',
  ];
  const webviewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig');
  return !!ua.match(webviewRegExp);
};

const delay = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export interface ImageWithFallback {
  credit: any;
  title: any;
  alt: any;
  uuid: string;
  src: string;
  fallback: string;
}

type GetImageListFn = (
  requirements: RequirementsProps,
  images?: string[],
  replaceCateg?: string
) => ImageWithFallback[];

const getImageList: GetImageListFn = (requirements, images = [], replaceCateg) => {
  const imageList = [...images];
  const fallbackList = [];

  if (!imageList.length) {
    imageList.push(
      `https://tourismebyca.twic.pics/static/${requirements.partner}/v2/etablissement/${replaceCateg}.jpg`
    );
    fallbackList.push(
      `https://tourismebyca.twic.pics/static/tca/v2/etablissement/${replaceCateg}.jpg`
    );
  }

  return imageList
    .map((i) => ({
      uuid: Math.random().toString(36).substring(7),
      src: i,
      fallback: fallbackList[i],
      credit: '',
      title: '',
      alt: '',
    }))
    ?.filter((i) => i.src || i.fallback);
};

const getFormattedEuro = (value: number) =>
  new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(value);

const capitalize = (s?: string | null) => s?.charAt(0).toUpperCase() + s.slice(1) ?? '';

const updateOrCreateMetaTag = (name: string, content: string) => {
  let meta = document.querySelector(`meta[name="${name}"]`);
  if (!meta) {
    meta = document.querySelector(`meta[property="${name}"]`);
  }

  if (meta) {
    meta.setAttribute('content', content);
  } else {
    meta = document.createElement('meta');
    if (name.startsWith('og:')) {
      meta.setAttribute('property', name);
    } else {
      meta.setAttribute('name', name);
    }
    meta.setAttribute('content', content);
    document.head.appendChild(meta);
  }
};

const dynamicClassName = (name: string, isFocus: boolean): string => {
  return isFocus ? `${name}-focused` : name;
};

const getAssignedDisabilitiesIcon = (disabilities: Disability[]): string | null => {
  if (!disabilities?.length) return null;

  if (typeof disabilities === 'string') {
    disabilities = (disabilities as string)?.split(',') as Disability[];
  }

  const disabilityToCode: Record<Disability, string> = {
    AUDITIF: 'A',
    MENTAL: 'Me',
    MOTEUR: 'M',
    VISUEL: 'V',
  };

  const codeOrder: Record<string, number> = {
    A: 1,
    Me: 2,
    M: 3,
    V: 4,
  };

  const codes = disabilities.map((d) => disabilityToCode[d]).filter(Boolean);
  codes.sort((a, b) => codeOrder[a] - codeOrder[b]);

  return `https://cdn.cibler.io/static/france/Logos_TourismeEtHandicap/Tourisme_Handi_${codes.join('')}.jpg`;
};

export interface SeoProps {
  metaTitle?: string;
  metaDescription?: string;
  metaImage?: {
    asset?: {
      url?: string;
    };
  };
  openGraphTitle?: string;
  openGraphImage?: any;
  keywords?: string;
  noIndex?: boolean;
}

const updateMetaTags = ({
  metaTitle,
  metaDescription,
  metaImage,
  openGraphTitle,
  openGraphImage,
  keywords,
  noIndex,
}: SeoProps) => {
  if (metaTitle) {
    document.title = metaTitle;
    updateOrCreateMetaTag('title', metaTitle);
    updateOrCreateMetaTag('og:title', openGraphTitle || metaTitle);
    updateOrCreateMetaTag('twitter:title', openGraphTitle || metaTitle);
  }

  if (metaDescription) {
    updateOrCreateMetaTag('description', metaDescription);
    updateOrCreateMetaTag('og:description', metaDescription);
    updateOrCreateMetaTag('twitter:description', metaDescription);
  }

  if (metaImage?.asset?.url || openGraphImage) {
    const imageUrl = openGraphImage ? openGraphImage : metaImage.asset.url;
    updateOrCreateMetaTag('og:image', imageUrl);
    updateOrCreateMetaTag('twitter:image', imageUrl);
  }

  if (keywords) {
    updateOrCreateMetaTag('keywords', keywords);
  }

  if (noIndex) {
    updateOrCreateMetaTag('robots', 'noindex, nofollow');
    updateOrCreateMetaTag('googlebot', 'noindex');
  } else {
    updateOrCreateMetaTag('robots', 'index, follow');
    updateOrCreateMetaTag('googlebot', 'index');
  }

  updateOrCreateMetaTag('og:type', 'article');
  updateOrCreateMetaTag('twitter:card', 'summary_large_image');
  updateOrCreateMetaTag('og:url', window.location.href);
};

const getStoreQualityLogoUrl = (id: string) => {
  if (!id) return null;

  const map = {
    '1 étoile': 2541,
    '2 étoiles': 2543,
    '3 étoiles': 2546,
    '4 étoiles': 2548,
    '5 étoiles': 2561,
  };

  if (!map[id]) return null;

  return `https://picto-label.charentestourisme.com/${map[id]}.svg`;
};

export {
  getQueryString,
  getCookies,
  replaceQueryString,
  replaceUrlParam,
  getQueryStrings,
  getOrCreateCiblerId,
  isDebugMode,
  isWebview,
  delay,
  getImageList,
  getFormattedEuro,
  capitalize,
  updateOrCreateMetaTag,
  dynamicClassName,
  getAssignedDisabilitiesIcon,
  updateMetaTags,
  getStoreQualityLogoUrl,
};
